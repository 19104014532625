var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ele-upload-image"},[(!_vm.crop)?_c('el-upload',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowUpload),expression:"isShowUpload"}],ref:"upload",style:({
      marginBottom: _vm.multiple && _vm.computedValues.length ? '20px' : '0px'
    }),attrs:{"accept":_vm.accept,"action":_vm.action,"before-upload":_vm.handleBeforeUpload,"data":_vm.data,"disabled":_vm.uploading,"drag":Boolean(_vm.drag),"headers":_vm.headers,"http-request":_vm.httpRequest,"limit":_vm.limit,"list-type":_vm.drag ? 'picture' : 'picture-card',"multiple":_vm.multiple,"name":_vm.name,"on-change":_vm.handleChange,"on-error":_vm.handleUploadError,"on-exceed":_vm.handleExceed,"on-success":_vm.handleUploadSuccess,"show-file-list":false,"withCredentials":_vm.withCredentials}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.uploading),expression:"uploading"}]},[(_vm.drag)?[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("\n          将文件拖到此处，或\n          "),_c('em',[_vm._v("点击上传")])])]:[_c('div',{style:({
            width: _vm.size + 'px',
            height: _vm.size + 'px',
            lineHeight: _vm.size + 'px'
          })},[_c('i',{staticClass:"el-icon-plus"})])]],2),(_vm.showTip)?_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("\n      请上传\n      "),_c('b',{staticStyle:{"color":"#F56C6C"}},[_vm._v(_vm._s(_vm.fileType.length ? _vm.fileType.join("/") : "图片"))]),_vm._v("\n      格式文件\n      "),(_vm.fileSize)?[_vm._v("\n        ，且大小不超过\n        "),_c('b',{staticStyle:{"color":"#F56C6C"}},[_vm._v(_vm._s(_vm.fileSize)+"MB")])]:_vm._e()],2):_vm._e()]):_vm._e(),(_vm.crop)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowUpload),expression:"isShowUpload"}],staticClass:"el-upload el-upload--picture-card",staticStyle:{"margin-bottom":"20px"},style:({
        width: _vm.size + 'px',
        height: _vm.size + 'px',
        lineHeight: _vm.size + 'px'
      }),on:{"click":function($event){_vm.isShowCrop = true}}},[_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),(_vm.isShowCrop)?_c('cropper',{ref:"cropper",staticClass:"ele-upload-image--cropper",attrs:{"field":_vm.name,"headers":_vm.headers,"height":_vm.cropHeight,"noCircle":_vm.cropHeight !== _vm.cropWidth,"params":_vm.data,"url":_vm.action,"width":_vm.cropWidth,"withCredentials":_vm.withCredentials,"img-format":"png"},on:{"crop-success":_vm.handleCropSuccess,"crop-upload-fail":_vm.handleCropUploadError,"crop-upload-success":_vm.handleCropUploadSuccess,"src-file-set":_vm.handleSetFileSet},model:{value:(_vm.isShowCrop),callback:function ($$v) {_vm.isShowCrop=$$v},expression:"isShowCrop"}}):_vm._e()],1):_vm._e(),_c('ele-gallery',{attrs:{"lazy":_vm.lazy,"remove-fn":_vm.handleRemove,"size":_vm.size,"sliceSingle":true,"source":_vm.value,"thumbSuffix":_vm.thumbSuffix,"title":_vm.title}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }